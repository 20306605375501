import * as React from "react";
import { Layout, LinkButton, SEO } from "../components";
import { QuoteForm, FeatureGridWithIconsTwoColumns } from "../components";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import {
  CheckIcon,
  CogIcon,
  CurrencyDollarIcon,
  GlobeAmericasIcon,
  KeyIcon,
  LinkIcon,
  MoonIcon,
  PaperClipIcon,
  PencilIcon,
  StarIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";

import PartnerLogoURL from "../images/logos/bbva_logo.png";

const benefits = [
  {
    name: "Servicio Llave en Mano",
    description: "Con Servicio Premium Post-Venta incluido",
    icon: KeyIcon,
  },
  {
    name: "Tecnología de Punta",
    description:
      "Trabajamos solamente con equipos Tier 1 e instaladores certificados en USA.",
    icon: StarIcon,
  },
  {
    name: "Beneficios Fiscales",
    description: "Deduce del ISR el 30% del valor del equipo en el 1er año.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Diseño Personalizado",
    description:
      "Según tus consumos, techo y sol, elegimos la ubicación y tamaño del sistema fotovoltaico.",
    icon: PencilIcon,
  },
  {
    name: "Garantía de Producción",
    description:
      "La más alta del mercado: Si el sistema no produce lo acordado, te reembolsamos.",
    icon: CheckIcon,
  },
  {
    name: "Trámites ante CFE",
    description:
      "Nos encargamos del papeleo y los permisos para la instalación de tu sistema.",
    icon: LinkIcon,
  },
  {
    name: "Mantenimiento y Monitoreo",
    description:
      "Preventivo y correctivo para que el equipo siempre funcione al 100%.",
    icon: WrenchIcon,
  },
  {
    name: "Responsabilidad Ambiental",
    description:
      "Mitiga la generación de CO2 al planeta y haz que tu empresa sea socialmente responsable",
    icon: GlobeAmericasIcon,
  },
];

const BBVAPage = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        ...SiteInformationFragment
      }
    }
  `);

  const metadata = site.siteMetadata;
  const metaPage = "bbva";
  const metaTitle = "BBVA + Bright";
  const metaDescription =
    "¡Disfruta de los beneficios de tener paneles solares sin comprarlos!";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": metadata.siteUrl,
        url: metadata.siteUrl,
        name: metadata.title,
        description: metaDescription,
        inLanguage: "es-MX",
      },
      {
        "@type": "ImageObject",
        "@id": `${metadata.siteUrl}${metadata.defaultOgImage}#primaryimage`,
        inLanguage: "es-MX",
        url: `${metadata.siteUrl}${metadata.defaultOgImage}`,
      },
      {
        "@type": "WebPage",
        "@id": `${metadata.siteUrl}/${metaPage}/#webpage`,
        url: `${metadata.siteUrl}/${metaPage}`,
        name: `${metaTitle} · ${metadata.title}`,
        isPartOf: { "@id": metadata.siteUrl },
        primaryImageOfPage: {
          "@id": `${metadata.siteUrl}${metadata.defaultOgImage}`,
        },
        breadcrumb: {
          "@id": `${metadata.siteUrl}/${metaPage}/#breadcrumb`,
        },
        inLanguage: "es-MX",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${metadata.siteUrl}/${metaPage}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${metadata.siteUrl}/${metaPage}#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": metadata.siteUrl,
              url: metadata.siteUrl,
              name: "Inicio",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": `${metadata.siteUrl}/${metaPage}`,
              url: `${metadata.siteUrl}/${metaPage}`,
              name: `${metaTitle} · ${metadata.title}`,
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout partnerLogo={PartnerLogoURL}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaMarkup={schemaMarkup}
      />
      <div className="bg-white py-8">
        <div className="mx-auto px-4 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl text-center">
          <h1 className="font-bold text-gray-900 tracking-tight text-4xl md:text-5xl text-center">
            Soluciones de eficiencia energética para tu negocio con Bright y
            BBVA
          </h1>
          <div className="italic text-xl mt-4">
            ¡Cambia a energía solar con las mejores opciones de financiamiento!
          </div>
          <div className="mt-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-8">
              <div className="order-2 md:order-1">
                <StaticImage
                  src="../images/industrial-installation.jpg"
                  alt="Bright + Citibanamex"
                />
                {/* Features Start */}
                <FeatureGridWithIconsTwoColumns
                  title="¿Por qué elegir Bright?"
                  features={benefits}
                />
                {/* Features End */}
              </div>
              <div className="w-full md:px-0 text-left order-1 md:order-2">
                <QuoteForm
                  form="bbva"
                  howDidYouHearAboutUs="bbva"
                  disabledSolutions={["residential", "commercial"]}
                  interactiveProposalVariant={false}
                />
                <div className="italic py-16">
                  <p className="font-bold">Términos y condiciones:</p>
                  <ul className="list-disc pl-5">
                    <li>Pagar más de $20,000 mxn de luz a CFE</li>
                    <li>Encontrarse en zona de cobertura</li>
                    <li>No tener licitación</li>
                    <li>Contar con techo disponible y en buenas condiciones</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BBVAPage;
